// Entry point for the build script in your package.json
import './channels'
import '@hotwired/turbo-rails'
import '@rails/actiontext'
import '@rails/activestorage'
import * as bootstrap from 'bootstrap'
import Trix from 'trix'

Trix.config.lang.indent = 'Increase Indent';
Trix.config.lang.outdent = 'Decrease Indent';
Trix.config.blockAttributes.heading1 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
  nestable: true
};

document.addEventListener('turbo:load', () => {
  initialize_tooltips();
  dismiss_flash_message();
});

// Automatically dismiss flash message after 5 seconds
window.dismiss_flash_message = () => {
  let alert_element = document.querySelector('#flash-message .alert');

  if (alert_element) {
    setTimeout(() => { bootstrap.Alert.getOrCreateInstance(alert_element).close() }, 5000);
  }
};

// Initialize modal
window.initialize_modal = element => bootstrap.Modal.getOrCreateInstance(element);

// Initialize tooltips
window.initialize_tooltips = () => {
  const tooltip_trigger_list = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltip_list = [...tooltip_trigger_list].map(tooltip_trigger_element => new bootstrap.Tooltip(tooltip_trigger_element));
};